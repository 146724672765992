<template>
            <main class="main">
            <!-- Content Header (Page header) -->
            <div class="content-header">
            <div class="container-fluid">
                <div class="mb-2 row">
                <div class="col-sm-6">

                    <h1 class="m-0 text-dark">Ventas</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">

                </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
            </div>
            <div class="container-fluid">
                <!-- Ejemplo de tabla Listado -->
                <div class="card">


                    <div class="card-header">
                        <i class="fa fa-align-justify"></i> Ventas
                        <button type="button" @click.prevent="mostrarDetalle()" class="btn btn-success">
                            <i class="fas fa-plus-square"></i>&nbsp;Nuevo
                        </button>

                        <!--
                        <button type="button" @click.prevent="validarUser()" class="btn btn-success">
                            <i class="fas fa-plus-square"></i>&nbsp;Validar
                        </button>
                       -->
                    </div>
                    <!------------------------------------------------- Listado Principal ------------------------------>
                    <template v-if="listado==1">
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Tienda</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="idlugar2">
                                    <option value="0" disabled>Seleccione</option>
                                            <option v-for="lugar in arrayLugar2" :key="lugar.id" :value="lugar.id"  v-text="lugar.nombre"></option>
                                    </select>

                                 </div>

                                <div class="input-group">

                                    <select class="form-control col-md-3" v-model="criterio">
                                      <option value="tipo_comprobante">Tipo Comprobante</option>
                                      <option value="num_comprobante">Número Comprobante</option>
                                      <option value="fecha_hora">Fecha-Hora</option>
                                    </select>
                                    <input type="text" v-model="buscar" @keyup.enter="listarVenta(1,buscar,criterio,idlugar2)" class="form-control" placeholder="Texto a buscar">
                                    <button type="submit" @click="listarVenta(1,buscar,criterio,idlugar2)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                </div>
                            </div>

                        </div>
                        <h5>Total Venta: Q {{calcularTotalv}}</h5>
                                <h6>Cantidad:  {{contarVenta}}</h6>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Opciones</th>
                                        <th>Usuario</th>
                                        <th>Cliente</th>
                                        <th>Tipo Comprobante</th>
                                        <th>Serie Comprobante</th>
                                        <th>Número Comprobante</th>
                                        <th>Fecha Hora</th>
                                        <th>Total</th>
                                        <th>Total_Gifts</th>
                                        <th>Total_Puntos</th>
                                        <th>Recibido</th>
                                        <th>Cambio</th>
                                        <th>TotalCosto</th>
                                        <th>Utilidad</th>

                                        <th>Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(venta,index) in arrayVenta" :key="venta.id">
                                        <td>
                                            {{(index+1)}}
                                        </td>
                                        <td>
                                            <button type="button" @click.prevent="verVenta(venta.id)" class="btn btn-success btn-sm">
                                            <i class="fas fa-eye"></i>
                                            </button> &nbsp;
                                            <button type="button" @click.prevent="pdfVenta(venta.id)" class="btn btn-info btn-sm">
                                            <i class="fas fa-file-pdf"></i>
                                            </button> &nbsp;
                                            <template v-if="venta.estado=='Registrado'">
                                                <button type="button" class="btn btn-danger btn-sm" @click.prevent="desactivarVenta(venta.id,venta.num_comprobante,venta.total,venta.totalg,venta.totalm)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </template>

                                            <!--

                                                obtenerGift(venta.id)
                                            <template v-if="venta.estado=='Anulado'">
                                                <button type="button" class="btn btn-danger btn-sm" @click.prevent="VerAnulacion(venta.id,venta.num_comprobante,venta.total)">
                                                    <i class="fas fa-eye"></i>
                                                </button>
                                            </template>
                                            -->
                                        </td>
                                        <td v-text="venta.usuario"></td>
                                        <td v-text="venta.nombre"></td>
                                        <td v-text="venta.tipo_comprobante"></td>
                                        <td v-text="venta.serie_comprobante"></td>
                                        <td v-text="venta.num_comprobante"></td>
                                        <td v-text="venta.fecha_hora"></td>
                                        <td v-text="venta.total"></td>
                                        <td v-text="venta.totalg"></td>
                                        <td v-text="venta.totalm"></td>
                                        <td v-text="venta.recibido"></td>
                                        <td v-text="venta.cambio"></td>
                                        <td v-text="venta.totalc"></td>
                                        <td v-text="venta.totalu"></td>

                                        <td>
                                            <div v-if="venta.estado=='Registrado'">
                                              <span class="badge badge-success"> Registrada</span>
                                             </div>

                                            <div v-if="venta.estado=='Anulado'">
                                                <span class="badge badge-danger"> Anulada</span>
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <nav>
                            <ul class="pagination">
                                <li class="page-item" v-if="pagination.current_page > 1">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio,idlugar2)">Ant</a>
                                </li>
                                <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio,idlugar2)" v-text="page"></a>
                                </li>
                                <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio,idlugar2)">Sig</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    </template>
                    <!--Fin Listado-->

                <!------------------------------------------------- Detalle ------------------------------>
                    <template v-else-if="listado==0">
                    <div class="card-body">
                        <div class="border form-group row">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Cliente <span style="color:red;" v-show="idcliente==0">(*Seleccione)</span></label>
                                    <div class="form-inline">
                                        <button @click.prevent="abrirModalC()" class="btn btn-success"><i class="fas fa-plus"></i></button>
                                        <button @click.prevent="abrirModalB()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                        <input type="text"  class="form-control " v-on:keyup.enter="buscarBcliente()" v-model="bcliente">
                                        <div>

                                             <input type="text"   readonly class="form-control limpiar" v-model="nit" placeholder="Nit">
                                        <input type="text"   readonly class="form-control limpiar" v-model="nombrep" placeholder="Nombre">
                                        <input type="text"   readonly class="form-control limpiar" v-model="direccion" placeholder="Direccion">
                                        <input type="text"   readonly class="form-control limpiar" v-model="telefono" placeholder="Telefono">
                                        </div>

                                    </div>
                              </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tipo Comprobante(*)</label>
                                    <select class="form-control" v-model="tipo_comprobante">
                                        <option value="0">Seleccione</option>
                                        <option value="BOLETA">Boleta</option>
                                        <option value="FACTURA">Factura</option>
                                        <option value="TICKET">Ticket</option>
                                        <option value="DEPOSITO">Deposito</option>
                                        <option value="SE">SE</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Serie Comprobante</label>
                                    <input type="text"  class="form-control" v-model="serie_comprobante" placeholder="000x">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Número Comprobante(*)</label>
                                    <input type="text"  class="form-control" v-model="num_comprobante" placeholder="000xx">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div v-show="errorVenta" class="form-group row div-error">
                                    <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjVenta" :key="error" v-text="error">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="border form-group row">
                             <div class="col-md-9">
                                 <div class="form-group">
                                       <label for="exampleFormControlSelect1">Tienda <span style="color:red;" v-show="idlugar==[]">(*Seleccione)</span></label>
                                       <select class="form-control" id="exampleFormControlSelect1" v-model="idlugar"  >
                                         <option value="0" disabled>Seleccione</option>
                                          <option v-for="lugar in arrayLugar" :key="lugar.id" :value="[lugar.id,lugar.nombre,lugar.direccion]"  v-text="lugar.nombre"></option>
                                        </select>

                                     </div>
                             </div>
                        </div>

                        <!--Boton_Producto-->
                        <div class="border form-group row">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Producto <span style="color:red;" v-show="idproducto==0">(*Seleccione)</span></label>
                                    <div class="form-inline">

                                        <button @click.prevent="abrirModal()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                         <input  v-on:keyup.enter="buscarProducto2()" v-model="codigo" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
                                         <button @click.prevent="abrirGift()" class="btn btn-info"><i class="fa fad fa-gift"></i></button>

                                    </div>


                                </div>
                            </div>



                        </div>


                        <div class="border form-group row">

                            <!-- Puntos_Tabla -->
                            <div class="table-responsive-md col-md-12">
                                <label>Membresia </label>
                                <div class="form-inline">
                                    <button @click.prevent="abrirPunto()" class="btn btn-success"><i class="fa fa-duotone fa-credit-card"></i></button>
                                    </div>

                                <table  class="table table-bordered table-striped ">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Opcion</th>
                                            <th>Codigo</th>

                                            <th>Saldo</th>
                                            <th>Punto</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalleP.length">
                                        <tr v-for="(detalleP,index) in arrayDetalleP" :key="detalleP.id">
                                            <td>{{(index + 1)}}</td>
                                            <td>
                                                <button @click="eliminarDetalleP(index)" type="button" class="btn btn-danger btn-sm">
                                                    <i class="fas fa-times-circle"></i>
                                                </button>
                                            </td>
                                            <td v-text="detalleP.codigoP">
                                            </td>

                                            <td v-text="detalleP.saldo">
                                            </td>

                                            <td>
                                                <span style="color:red;" v-show="(detalleP.saldo < detalleP.punto)">Cantidad es Mayor al Saldo</span>
                                                <input v-model.number="detalleP.punto"  type="number" value="1" class="form-control">
                                            </td>

                                        </tr>
                                    </tbody>

                                </table>



                            </div>
                            <!-- Gift_Tabla -->
                            <div class="table-responsive-md col-md-12">
                                <label>GiftCard </label>
                                <table  class="table table-bordered table-striped ">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Opcion</th>
                                            <th>Codigo</th>
                                            <th>Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalleG.length">
                                        <tr v-for="(detalleG,index) in arrayDetalleG" :key="detalleG.id">
                                            <td>{{(index + 1)}}</td>
                                            <td>
                                                <button @click="eliminarDetalleG(index)" type="button" class="btn btn-danger btn-sm">
                                                    <i class="fas fa-times-circle"></i>
                                                </button>
                                            </td>
                                            <td v-text="detalleG.codigoG">
                                            </td>
                                            <td v-text="detalleG.montoG">
                                            </td>

                                        </tr>
                                    </tbody>

                                </table>



                            </div>



                            <div class="table-responsive-md col-md-12">

                                <div class="col-md-4">
                                    <label>Productos </label>
                                    <div class="form-group">
                                        <label>Total Articulos</label>
                                        <p>{{totalArticulo}}</p>
                                    </div>
                               </div>
                                <table  class="table table-bordered table-striped ">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Opciones</th>
                                            <th>Codigo</th>
                                            <th>Producto</th>
                                            <th id="costo">Costo</th>


                                            <th>Precio Normal</th>
                                            <th>Precio Especial</th>

                                            <th>Cantidad</th>

                                            <th>Descuento</th>
                                            <th>Subtotal</th>
                                            <th id="totalc">SubtotalCosto</th>
                                            <th id="totalu">SubtotalUtilidad</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalle.length">
                                        <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                            <td>
                                                {{(index+1)}}
                                            </td>
                                            <td>
                                                <button @click="eliminarDetalle(index)" type="button" class="btn btn-danger btn-sm">
                                                    <i class="fas fa-times-circle"></i>
                                                </button>
                                            </td>
                                            <td v-text="detalle.codigo">
                                            </td>
                                            <td v-text="detalle.producto">
                                            </td>
                                             <td>
                                                <input style="width: 100px" disabled v-model="detalle.costo" type="number" value="4" class="form-control" >
                                            </td>



                                            <td>
                                                {{detalle.precionormal}}
                                            </td>

                                            <td>
                                                <!--
                                                <input style="width: 100px" v-model="detalle.precio" type="number" value="3" class="form-control" >
                                                -->


                                                     <template v-if="detalle.cantidad==1">
                                                {{detalle.precio=detalle.precio01}}

                                                </template>

                                                <template v-if="detalle.cantidad==2">

                                                 {{detalle.precio=detalle.precio0}}

                                                </template>

                                                  <template v-if="detalle.cantidad>=3 && detalle.cantidad<=5">

                                                  {{detalle.precio=detalle.precio1}}

                                                </template>
                                                 <template v-if="detalle.cantidad>=6 && detalle.cantidad<=11">

                                                 {{detalle.precio=detalle.precio2}}

                                                </template>

                                                <template v-if="detalle.cantidad>=12 && detalle.cantidad<=24">

                                                   {{detalle.precio=detalle.precio3}}

                                                </template>

                                                <template v-if="detalle.cantidad>=25">

                                                 {{detalle.precio=detalle.precio4}}


                                                </template>

                                            </td>
                                            <td>
                                                 <span style="color:red;" v-show="(detalle.stock < detalle.cantidad)">Cantidad Mayor al Stock</span>
                                                <input v-model.number="detalle.cantidad"  type="number" value="2" class="form-control">
                                            </td>
                                            <td >
                                               <!--
                                                 {{detalle.descuento=0}}
                                                -->
                                                 <template v-if="detalle.cantidad ==1 ">
                                                    {{detalle.descuento=detalle.precionormal*detalle.cantidad-detalle.precio01*detalle.cantidad}}

                                                </template>
                                                <template v-if="detalle.cantidad==2">
                                                  {{detalle.descuento=detalle.precionormal*detalle.cantidad-detalle.precio0*detalle.cantidad}}

                                                </template>
                                                 <template v-if="detalle.cantidad>=3 && detalle.cantidad<=5">
                                                 {{detalle.descuento=detalle.precionormal*detalle.cantidad-detalle.precio1*detalle.cantidad}}
                                                </template>
                                                  <template v-if="detalle.cantidad>=6 && detalle.cantidad<=11">
                                                 {{detalle.descuento=detalle.precionormal*detalle.cantidad-detalle.precio2*detalle.cantidad}}
                                                </template>
                                                <template v-if="detalle.cantidad>=12 && detalle.cantidad<=24">
                                                 {{detalle.descuento=detalle.precionormal*detalle.cantidad-detalle.precio3*detalle.cantidad}}
                                                </template>
                                                 <template v-if="detalle.cantidad>=25">
                                                 {{detalle.descuento=detalle.precionormal*detalle.cantidad-detalle.precio4*detalle.cantidad}}
                                                </template>




                                            </td>
                                            <td>
                                                {{(detalle.precio*detalle.cantidad).toFixed(2)}}
                                            </td>
                                             <td id="totalc2">
                                                {{(detalle.costo*detalle.cantidad).toFixed(2)}}
                                            </td>
                                             <td id="totalu2">
                                                {{((detalle.precio*detalle.cantidad)-(detalle.costo*detalle.cantidad)).toFixed(2)}}
                                            </td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Parcial:</strong></td>
                                            <td>Q {{calcularTotalN}}</td>
                                        </tr>

                                       <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Descuento:</strong></td>
                                            <td>Q {{(totalDescuento).toFixed(2)}}</td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Total:</strong></td>
                                            <td>Q {{totalParcial=calcularTotal}}</td>
                                        </tr>

                                      <!--
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Total Neto:</strong></td>
                                            <td>Q {{total=calcularTotal}}</td>
                                        </tr>
                                        -->
                                        <tr  style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>TotalCosto:</strong></td>
                                            <td>Q {{totalc=calcularTotalc}}</td>
                                        </tr>
                                        <tr  style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>TotalUtilidad:</strong></td>
                                            <td>Q {{totalu=calcularTotalu}}</td>
                                        </tr>
                                    </tbody>





                                </table>

                                <!--  ---------  Tabla cotizar ----------->

                                 <table id="producto"  v-show="cotizar" class="table table-bordered table-striped ">


                                    <thead>
                                        <tr>
                                           <th>No</th>
                                           <th>Codigo</th>
                                            <th>Producto</th>

                                            <th>Precio</th>
                                            <th>Cantidad</th>
                                            <th>Descuento</th>
                                            <th>Subtotal</th>

                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalle.length">
                                        <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                            <td>{{(index+1)}}</td>
                                            <td v-text="detalle.codigo"></td>
                                            <td v-text="detalle.producto"></td>

                                            <td v-text="detalle.precio"></td>
                                            <td v-text="detalle.cantidad"></td>
                                            <td v-text="detalle.descuento"></td>
                                            <td>
                                               Q {{detalle.precio*detalle.cantidad}}
                                            </td>

                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Parcial:</strong></td>
                                            <td>Q {{calcularTotalN}}</td>
                                        </tr>

                                       <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Descuento:</strong></td>
                                            <td>Q {{(totalDescuento).toFixed(2)}}</td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Total:</strong></td>
                                            <td>Q {{(calcularTotal)}}</td>
                                        </tr>

                                    </tbody>



                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5">
                                                NO hay productos agregados
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">

                                Membresia: Q{{ totalPuntos=calcularPuntos }}<br>
                                GiftCard: Q{{ totalGift=calcularGift }}<br>
                                Venta: Q{{calcularTotal}}<br>
                                -------------------------------<br>
                                Total: Q{{ restaTotalT=(calcularTotal - (calcularGift + calcularPuntos)) }}<br>

                               Monto: <input type="number" :disabled = "deshabilitar_boton==1"  @keyup.enter="registrarVenta()" v-model.number="recibido"><br>

                              Cambio Q{{(cambio=recibido-restaTotalT).toFixed(2)}}<br>

                                     <button type="button"  class="btn btn-primary" :disabled = "deshabilitar_boton==1"  @click="registrarVenta()">Registrar Venta</button>
                                    <!--
                                    <button type="button"  class="btn btn-info"  :disabled = "deshabilitar_boton==1" @click="ventaPos()">Venta Pos</button>
                                     -->
                                     <button type="button"  class="btn btn-warning"  @click="cotizacion()">cotización</button>
                                     <button type="button" @click="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>


                            </div>
                        </div>
                    </div>
                    </template>
                    <!-- Fin Detalle-->

                    <!------------------------------------------------- Ver Venta_detalle ------------------------------>
                    <template v-else-if="listado==2">
                    <div class="card-body">
                        <div class="border form-group row">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label for="">Cliente</label>
                                    <p v-text="cliente"></p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tipo Comprobante</label>
                                    <p v-text="tipo_comprobante"></p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Serie Comprobante</label>
                                    <p v-text="serie_comprobante"></p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Número Comprobante</label>
                                    <p id="numVenta" v-text="num_comprobante"></p>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Total Articulos</label>
                                    <p>{{totalArticulo}}</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Estado</label>
                                    <p id="estado" v-text="estado"></p>

                                </div>
                            </div>
                        </div>
                        <div class="border form-group row">
                            <div class="table-responsive col-md-12">

                                <table class="table table-bordered table-striped table-sm">

                                    <thead>
                                        <tr>



                                            <th>No</th>
                                            <th>Codigo</th>
                                            <th>Marca</th>
                                            <th>Genero</th>
                                            <th>Diseño</th>
                                            <th>Tela</th>
                                            <th>Color</th>
                                            <th>Talla</th>
                                            <th>Lote</th>
                                            <th>Producto</th>
                                            <th>Costo</th>
                                            <th>Precio_normal</th>
                                            <th>Precio</th>
                                            <th>Cantidad</th>
                                            <th>Descuento</th>
                                            <th>Subtotal</th>
                                            <th>SubtotalCosto</th>
                                            <th>SubtotalUlitidad</th>


                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalle.length">
                                        <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                            <td>
                                                {{(index+1)}}
                                            </td>

                                            <td v-text="detalle.codigo">
                                            </td>
                                           <td v-text="detalle.marca">
                                            </td>
                                           <td v-text="detalle.genero">
                                            </td>
                                           <td v-text="detalle.diseno">
                                            </td>
                                           <td v-text="detalle.tela">
                                            </td>
                                           <td v-text="detalle.color">
                                            </td>
                                           <td v-text="detalle.talla">
                                            </td>
                                           <td v-text="detalle.lote">
                                            </td>
                                            <td v-text="detalle.articulo">
                                            </td>

                                            <td v-text="detalle.costo">
                                            </td>
                                            <td v-text="detalle.precionormal">
                                            </td>
                                            <td v-text="detalle.precio">
                                            </td>
                                            <td v-text="detalle.cantidad">
                                            </td>
                                            <td v-text="detalle.descuento">
                                            </td>
                                            <td>
                                                {{detalle.precio*detalle.cantidad}}
                                            </td>
                                            <td>
                                                {{detalle.costo*detalle.cantidad}}
                                            </td>
                                            <td>
                                                {{(detalle.precio*detalle.cantidad)-(detalle.costo*detalle.cantidad)}}
                                            </td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Parcial:</strong></td>
                                            <td>Q {{calcularTotalN}}</td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Descuento:</strong></td>
                                            <td>Q {{(totalDescuento).toFixed(2)}}</td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total :</strong></td>
                                            <td>Q {{totalParcial=(total-totalImpuesto).toFixed(2)}}</td>
                                        </tr>
                                        <!--
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Impuesto:</strong></td>
                                            <td>Q {{totalImpuesto=((total*impuesto)).toFixed(2)}}</td>
                                        </tr>
                                    -->
                                    <!--
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Neto:</strong></td>
                                            <td >Q <span id="totalVentaArticulo">{{total}}</span> </td>
                                        </tr>
                                        -->
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>TotalCosto:</strong></td>
                                            <td>Q {{totalc=calcularTotalc}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>TotalUtilidad:</strong></td>
                                            <td>Q {{totalu=calcularTotalu}}</td>
                                        </tr>



                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="4">
                                                NO hay productos agregados
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <button type="button" @click="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>

                            </div>
                        </div>
                    </div>
                    </template>
                    <!-- fin ver venta -->
                </div>
                <!-- Fin ejemplo de tabla Listado -->
            </div>

   <!------------------------------------------------- Modal agregar ------------------------------>

            <div class="p-5 modal fade" tabindex="-1" :class="{'mostrar' : modal}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-primary modal-lg modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" v-text="tituloModal"></h4>
                            <button type="button" class="close" @click.prevent="cerrarModal()" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <div class="col-md-6">

                                    <div class="input-group">

                                        <select class="form-control col-md-3" v-model="criterioA">
                                        <option value="codigo">Codigo</option>
                                        <option value="nombre">Nombre</option>
                                        <option value="descripcion">Descripción</option>
                                        </select>
                                        <input type="text" v-model="buscarA" @keyup.enter="listarProducto(1,buscarA,criterioA,idlugar)" class="form-control" placeholder="Texto a buscar">
                                        <button type="submit" @click="listarProducto(1,buscarA,criterioA,idlugar)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>
                                            <th>Marca</th>
                                            <th>Codigo</th>
                                            <th>Nombre</th>
                                            <th>Descripcion</th>

                                            <th>Precio Venta</th>
                                            <th>Precio Costo</th>
                                            <th>Stock</th>
                                            <th>Estado</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="producto in arrayProducto" :key="producto.id">
                                            <td>
                                                <button type="button" @click.prevent="agregarDetalleModal(producto)" class="btn btn-success btn-sm">
                                                <i class="fas fa-check-circle"></i>
                                                </button>
                                            </td>
                                           <td v-text="producto.nombre_marca"></td>
                                           <td v-text="producto.codigo"></td>
                                            <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.nombre"></td>
                                            <td v-else v-text="producto.nombre"></td>
                                             <td v-text="producto.descripcion"></td>
                                            <td v-text="producto.precio_venta"></td>
                                            <td v-text="producto.precio_costo"></td>
                                            <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.stock"></td>
                                             <td v-else v-text="producto.stock"></td>
                                            <td>
                                                <div v-if="producto.condicion">
                                                    <span class="badge badge-success">Activo</span>
                                                </div>
                                                <div v-else>
                                                    <span class="badge badge-danger">Desactivado</span>
                                                </div>

                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <nav>
                                <ul class="float-right m-0 pagination pagination-sm">
                                <li class="page-item" v-if="pagination2.current_page > 1">
                                    <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page - 1,buscarA,criterioA,idlugar)" >&laquo;</a>
                                </li>
                                <li class="page-item" v-for="page in pagesNumber2" :key="page" :class="[page == isActived2 ? 'active' : '']">
                                    <a class="page-link" href="#" @click="cambiarPagina2(page,buscarA,criterioA,idlugar)" v-text="page"></a>
                                    </li>
                                <li class="page-item" v-if="pagination2.current_page < pagination2.last_page">
                                    <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page + 1,buscarA,criterioA,idlugar)">&raquo;</a>
                                    </li>
                                </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="cerrarModal()">Cerrar</button>

                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!--Fin del modal-->




            <div tabindex="-1" class="p-5 modal fade" :class="{'mostrar' : modal2}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal2"></h4>
              <button type="button" class="close" @click="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">

                   <div class="card-body table-responsive">
                <table  class="table table-bordered table-striped">
                  <thead>
                  <tr>


                    <th>Nombre</th>
                    <th>Stock</th>
                    <th>Descripcion</th>
                    <th>Minimo</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="producto in arrayProducto" :key="producto.id" >


                                    <td v-if="producto.stock <= producto.minimo"  v-text="producto.nombre"></td>
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.stock"></td>
                                    <td v-if="producto.stock <= producto.minimo" v-text="producto.descripcion"></td>
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.minimo"></td>




                  </tr>


                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>
                <div class="clearfix card-footer">
                 <nav>
                <ul class="float-right m-0 pagination pagination-sm">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio,idlugar2)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio,idlugar2)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio,idlugar2)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>

            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click="cerrarModal()">Close</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->




             <!------------------------------------------------- Modal Cliente ------------------------------>

            <div class="p-5 modal fade" tabindex="-1" :class="{'mostrar' : modalB}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-primary modal-lg modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" v-text="tituloModalB"></h4>
                            <button type="button" class="close" @click="cerrarModalB()" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <select class="form-control col-md-3" v-model="criterioB">
                                        <option value="nit">Nit</option>
                                        <option value="nombre">Nombre</option>

                                        </select>
                                        <input type="text" v-model="buscarB" @keyup.enter="listarCliente(buscarB,criterioB)" class="form-control" placeholder="Texto a buscar">
                                        <button type="submit" @click="listarCliente(buscarB,criterioB)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table  class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>
                                            <th>Nit</th>
                                            <th>Nombre</th>
                                            <th>Direccion</th>
                                            <th>Telefono</th>
                                             <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="cliente in arrayCliente" :key="cliente.id">
                                            <td>
                                                <button type="button" @click.prevent="agregarClienteModal(cliente)" class="btn btn-success btn-sm">
                                                <i class="fas fa-check-circle"></i>
                                                </button>
                                            </td>

                                            <td v-text="cliente.nit"></td>
                                            <td v-text="cliente.nombre"></td>
                                            <td v-text="cliente.direccion"></td>
                                            <td v-text="cliente.telefono"></td>
                                            <td>
                                                <div v-if="cliente.condicion">
                                                    <span class="badge badge-success">Activo</span>
                                                </div>
                                                <div v-else>
                                                    <span class="badge badge-danger">Desactivado</span>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="cerrarModalB()">Cerrar</button>
                            <button type="button" v-if="tipoAccion==1" class="btn btn-primary" @click.prevent="registrarCliente()">Guardar</button>
                            <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click.prevent="actualizarCliente()">Actualizar</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!--Fin del modal-->

            <div tabindex="-1" class="p-5 modal fade" :class="{'mostrar' : modalC}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModalC"></h4>
              <button type="button" class="close" @click.prevent="cerrarModalC()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                  <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                 <div class="form-group row">
                    <label for="recipient-name" class="col-form-label">Nit:</label>
                     <input type="text" class="form-control" @blur="duplicado()"   v-model="nit">
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Nombre:</label>
                      <input type="text" class="form-control"  v-model="nombre" >
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Direccion:</label>
                      <input type="text" class="form-control"  v-model="direccion" >
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Telefono:</label>
                      <input type="text" class="form-control"  v-model="telefono" >
                  </div>


                   <div v-show="errorCliente" class="form-growp row div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjCliente" :key="error" v-text="error">

                                        </div>
                                    </div>
                  </div>
                  <div v-show="deshabilitar_boton" class="form-growp row div-error">
                    <p class="text-center text-error">El Nit ya Existe!</p>
                  </div>
                  </form>
                </div>
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click.prevent="cerrarModalC()">Close</button>
              <button type="button" :disabled = "deshabilitar_boton==1" class="btn btn-primary" @click="registrarCliente()">Guardar</button>

            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->



       <!------------------------------------------------- Modal_Gif ------------------------------>

       <div class="p-5 modal fade" tabindex="-1" :class="{'mostrar' : modalG}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-primary modal-lg modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" v-text="tituloModalG"></h4>
                            <button type="button" class="close" @click.prevent="cerrarModalG()" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <div class="col-md-6">

                                    <div class="input-group">


                                        <select class="form-control col-md-3" v-model="criterioG">
                                        <option value="codigo">Codigo</option>
                                        </select>
                                        <input type="text" v-model="buscarG" @keyup.enter="listarGift(1,buscarG,criterioG)" class="form-control" placeholder="Texto a buscar">
                                        <button type="submit" @click="listarGift(1,buscarG,criterioG)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>

                                            <th>Codigo</th>
                                            <th>Monto</th>
                                            <th>FechaLimite</th>

                                            <th>Estado</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="gifts in arrayGift" :key="gifts.id">
                                            <td>
                                                <template v-if="gifts.estado==1">
                                                    <button type="button" @click.prevent="agregarDetalleModalG(gifts)" class="btn btn-success btn-sm">
                                                <i class="fas fa-check-circle"></i>
                                                </button>
                                                </template>
                                            </td>
                                           <td v-text="gifts.codigo"></td>
                                           <td v-text="gifts.monto"></td>
                                           <td v-text="gifts.fecha_end"></td>
                                            <td>
                                                <div v-if="gifts.estado==1">
                                                  <span class="badge badge-success"> Activo </span>
                                                </div>
                                                <div v-if="gifts.estado==2">
                                                    <span class="badge badge-danger"> Cangeado </span>
                                                </div>
                                                <div v-if="gifts.estado==3">
                                                    <span class="badge badge-warning"> Caducado </span>
                                                </div>


                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <nav>
                                    <!--
                                <ul class="float-right m-0 pagination pagination-sm">
                                <li class="page-item" v-if="pagination2.current_page > 1">
                                    <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page - 1,buscarG,criterioG)" >&laquo;</a>
                                </li>
                                <li class="page-item" v-for="page in pagesNumber2" :key="page" :class="[page == isActived2 ? 'active' : '']">
                                    <a class="page-link" href="#" @click="cambiarPagina2(page,buscarG,criterioG)" v-text="page"></a>
                                    </li>
                                <li class="page-item" v-if="pagination2.current_page < pagination2.last_page">
                                    <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page + 1,buscarG,criterioG)">&raquo;</a>
                                    </li>
                                </ul>
                                -->
                                </nav>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="cerrarModalG()">Cerrar</button>

                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!--Fin del modal-->


             <!------------------------------------------------- Modal_Punto ------------------------------>

       <div class="p-5 modal fade" tabindex="-1" :class="{'mostrar' : modalP}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-primary modal-lg modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" v-text="tituloModalG"></h4>
                            <button type="button" class="close" @click.prevent="cerrarModalG()" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <div class="col-md-6">

                                    <div class="input-group">


                                        <select class="form-control col-md-3" v-model="criterioP">
                                        <option value="codigo">Codigo</option>
                                        <option value="telefono">Telefono</option>
                                        </select>
                                        <input type="text" v-model="buscarP" @keyup.enter="listarPunto(1,buscarP,criterioP)" class="form-control" placeholder="Texto a buscar">
                                        <button type="submit" @click="listarPunto(1,buscarP,criterioP)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>

                                            <th>Codigo</th>
                                            <th>Nombre</th>
                                            <th>Telefono</th>
                                            <th>Saldo</th>


                                            <th>Estado</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="puntos in arrayPunto" :key="puntos.id">
                                            <td>
                                                <template v-if="puntos.estado==1">
                                                    <button type="button" @click.prevent="agregarDetalleModalP(puntos)" class="btn btn-success btn-sm">
                                                <i class="fas fa-check-circle"></i>
                                                </button>
                                                </template>
                                            </td>
                                           <td v-text="puntos.codigo"></td>
                                           <td v-text="puntos.nombre"></td>
                                           <td v-text="puntos.telefono"></td>
                                           <td v-text="puntos.saldo"></td>
                                            <td>
                                                <div v-if="puntos.estado==1">
                                                  <span class="badge badge-success"> Activo </span>
                                                </div>
                                                <div v-if="puntos.estado==2">
                                                    <span class="badge badge-warning"> Desactivados </span>
                                                </div>


                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <nav>
                                    <!--
                                <ul class="float-right m-0 pagination pagination-sm">
                                <li class="page-item" v-if="pagination2.current_page > 1">
                                    <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page - 1,buscarG,criterioG)" >&laquo;</a>
                                </li>
                                <li class="page-item" v-for="page in pagesNumber2" :key="page" :class="[page == isActived2 ? 'active' : '']">
                                    <a class="page-link" href="#" @click="cambiarPagina2(page,buscarG,criterioG)" v-text="page"></a>
                                    </li>
                                <li class="page-item" v-if="pagination2.current_page < pagination2.last_page">
                                    <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page + 1,buscarG,criterioG)">&raquo;</a>
                                    </li>
                                </ul>
                                -->
                                </nav>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="cerrarModalP()">Cerrar</button>

                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!--Fin del modal-->

        </main>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { async } from 'q';

    export default {
        data (){
            return {
                venta_id: 0,
                idcliente:0,
                idlugar:[],
                idlugar2:1,
                nombrelugar: '',
                direccionlugar: '',

                nit: '',
                lugar:'',
                cotizar:0,
                cliente:'',
                codigo:'',
                nombre : '',
                nombrep : '',
                direccion : '',
                telefono : '',
                bcliente : '',
                tipo_comprobante : '',
                serie_comprobante : '',
                num_comprobante : '',
                estado : '',
                urlhttp: this.$api_url,
                deshabilitar_boton:0,
                impuesto: 0.0,
                total:0.0,
                totalc:0.0,
                totalu:0.0,
                recibido:0.0,
                totalImpuesto: 0.0,
                totalParcial: 0.0,
                arrayVenta : [],
                arrayCliente: [],
                arrayDetalle : [],
                arrayLugar : [],
                 arrayLugar2 : [],
                 arrayAnularV: [],
                 arrayBuscarCliente: [],
                 arrayAnulacion: [],


                razonAnulacion : '',

                /** Gift_Global */
                arrayGift: [],
                arrayDetalleG:[],
                criterioG:'codigo',
                codigoG:'',
                montoG:'',
                modalG: 0,
                idgift:'',
                buscarG :'',
                tituloModalG:'',
                arrayAnularGift: [],
                totalGift: 0,

                 /** Punto_Global */
                 arrayPunto: [],
                arrayDetalleP:[],
                criterioP:'codigo',
                codigoP:'',
                saldo:'',
                arrayAnularPunto: [],
                arrayAnularCargar: [],
                totalPuntos: 0,

                modalP: 0,
                idPunto:'',
                buscarP:'',
                tituloModalP:'',


                 //modalG: 0,
                 //criterioG:'codigo',

                restaTotalT: 0,

                pagination3 : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                },
                offset3 : 3,



                listado:1,
                modal : 0,
                modalB : 0,
                modalC : 0,
                 modal2 : 0,
                tituloModal : '',
                tituloModalB : '',
                tituloModalC : '',
                 tituloModal2 : '',
                tipoAccion : 0,
                errorVenta : 0,
                 errorCliente : 0,
                errorMostrarMsjVenta : [],
                errorMostrarMsjCliente : [],
                'mensaje':'',
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                },
                offset : 3,

                 pagination2 : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                },
                offset2 : 3,
                criterio : 'fecha_hora',
                buscar : '',
                criterioA:'nombre',
                buscarA: '',
                criterioB:'nombre',
                buscarB: '',
                arrayProducto: [],
                idproducto: 0,
               // codigo: '',
                producto: '',
                precio: 0,
                cantidad:0,
                descuento: 0,
                cambio: 0,
                negativo: 0,
                stock: 0
            }
        },
        components: {

        },
        computed:{
            isActived: function(){
                return this.pagination.current_page;
            },
            isActived2: function(){
                return this.pagination2.current_page;
            },
            isActived3: function(){
                return this.pagination3.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }

                var from = this.pagination.current_page - this.offset;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;
            },
            pagesNumber2: function() {
                if(!this.pagination2.to) {
                    return [];
                }

                var from = this.pagination2.current_page - this.offset2;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset2 * 2);
                if(to >= this.pagination2.last_page){
                    to = this.pagination2.last_page;
                }

                var pagesArray2 = [];
                while(from <= to) {
                    pagesArray2.push(from);
                    from++;
                }
                return pagesArray2;
            },
            pagesNumber3: function() {
                if(!this.pagination2.to) {
                    return [];
                }

                var from = this.pagination2.current_page - this.offset2;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset3 * 2);
                if(to >= this.pagination3.last_page){
                    to = this.pagination3.last_page;
                }

                var pagesArray3 = [];
                while(from <= to) {
                    pagesArray3.push(from);
                    from++;
                }
                return pagesArray3;
            },


     totalDescuento: function(){
      var resultado=0.0;

                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado+=parseFloat( this.arrayDetalle[i].descuento)
                }
                return resultado;

            },

              calcularTotalN: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].precionormal*this.arrayDetalle[i].cantidad)
                }
                return resultado.toFixed(2);
            },


            calcularTotalv: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayVenta.length;i++){

                     if(this.arrayVenta[i].estado=="Registrado"){
                       resultado +=parseFloat(this.arrayVenta[i].total)
                     }

                }
                return resultado.toFixed(2);
            },
            contarVenta: function(){

               var resultado=0.0;
                for(var i=0;i<this.arrayVenta.length;i++){

                    resultado =i+1;

                }
                return resultado;
            },
            calcularTotal: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad)
                }
                return resultado.toFixed(2);
            },


            calcularTotalc: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad)
                }
                return resultado.toFixed(2);
            },


            calcularTotalu: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+((this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad)-(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad))
                }
                return resultado.toFixed(2);
            },


            totalArticulo: function(){
                var result=0;
                 for(var i=0;i<this.arrayDetalle.length;i++){

                    result+=(this.arrayDetalle[i].cantidad)
                }
                return result;


            },

            contarAlerta: function(){

              var resultado=0;
                for(var i=0;i<this.arrayProducto.length;i++){

                    if(this.arrayProducto[i].stock <= this.arrayProducto[i].minimo){

                      resultado +=1;
                    }


                }
                return resultado;
            },



            /** Computer_Gift */


            calcularGift: function(){
                let resultado=0;
                for(var i=0;i<this.arrayDetalleG.length;i++){

                     if(this.arrayDetalleG[i].estadoG=="1"){

                       resultado +=parseFloat(this.arrayDetalleG[i].montoG)

                     }

                }
                return resultado;
            },


                      /** Computer_Punto */


                      calcularPuntos: function(){

                     let resultado=0;
                for(var i=0;i<this.arrayDetalleP.length;i++){

                     if(this.arrayDetalleP[i].estadoP=="1"){

                       resultado +=parseFloat(this.arrayDetalleP[i].punto)

                     }

                }
                return resultado;
            }





        },
        methods : {


      /**************************************************************************************** */
            listarVenta (page,buscar,criterio,idlugar2){

                let me=this;
                var url= '/venta?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio + '&idlugar2='+ idlugar2;
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayVenta = respuesta.ventas.data;
                    me.pagination= respuesta.pagination;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

       /**************************************************************************************** */
            buscarCliente(){
                let me=this;


                var url= '/cliente/selectCliente?filtro='+search;
                axios.get(url).then(function (response) {
                    let respuesta = response.data;
                    me.arrayCliente=respuesta.clientees;

                })
                .catch(function (error) {
                    console.log(error);
                });
            },

        /**************************************************************************************** */
            getDatosCliente(val1){
                let me = this;
                me.loading = true;
                me.idcliente = val1.id;
            },

         /**************************************************************************************** */
            buscarProducto(){
                let me=this;
                var url= '/existencia/buscarProducto?filtro=' + me.nombre;

                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos;

                    if (me.arrayProducto.length>0){
                        me.producto=me.arrayProducto[0]['nombre'];
                        me.idproducto=me.arrayProducto[0]['id'];
                    }
                    else{
                        me.producto='No existe producto';
                        me.idproducto=0;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            buscarProducto2(){

               let me=this;
                let idlugar = me.idlugar;
               var url= '/existencia/buscarProducto2?filtro=' + me.codigo + '&idlugar='+ idlugar[0];



                axios.get(url).then(function (response) {


                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos;





                    if (me.arrayProducto.length>0){
                        me.codigo=me.arrayProducto[0]['codigo'];
                        me.idproducto=me.arrayProducto[0]['id'];
                        me.nombre=me.arrayProducto[0]['nombre'];
                        me.costo=me.arrayProducto[0]['precio_costo'];
                        me.precio=me.arrayProducto[0]['precio_venta'];
                        me.precionormal= me.arrayProducto[0]['precio_venta'],

                            me.precio01= me.arrayProducto[0]['precio01'],
                            me.precio0= me.arrayProducto[0]['precio0'],
                            me.precio1= me.arrayProducto[0]['precio1'],
                            me.precio2= me.arrayProducto[0]['precio2'],
                            me.precio3= me.arrayProducto[0]['precio3'],
                            me.precio4= me.arrayProducto[0]['precio4'],
                        me.stock= me.arrayProducto[0]['stock'];


                 if(me.encuentra(me.arrayProducto[0]['id']) ){
                     /*
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                            */


                         //

                                  for(var i=0;i<me.arrayDetalle.length;i++){
                                    if(me.arrayDetalle[i].idproducto==me.idproducto){
                                        me.arrayDetalle[i].cantidad= me.arrayDetalle[i].cantidad + 1;
                                    }
                               }


                    }else{
                        me.arrayDetalle.push({
                            idproducto: me.arrayProducto[0]['id'],
                            codigo: me.arrayProducto[0]['codigo'],
                            producto: me.arrayProducto[0]['nombre'],
                            cantidad: 1,
                            costo: me.arrayProducto[0]['precio_costo'],
                            precio: me.arrayProducto[0]['precio_venta'],
                            descuento: 0,
                             precionormal: me.arrayProducto[0]['precio_venta'],

                            precio01: me.arrayProducto[0]['precio01'],
                            precio0: me.arrayProducto[0]['precio0'],
                            precio1: me.arrayProducto[0]['precio1'],
                            precio2: me.arrayProducto[0]['precio2'],
                            precio3: me.arrayProducto[0]['precio3'],
                            precio4: me.arrayProducto[0]['precio4'],
                            stock: me.arrayProducto[0]['stock'],
                        });
                    }


/*
                         if(me.arrayDetalle[i].idproducto==me.arrayProducto[0]['id']){

                             me.arrayDetalle.push({
                                 cantidad: cantidad+1
                                 });

                         }else{

                             me.arrayDetalle.push({
                            idproducto: me.arrayProducto[0]['id'],
                            codigo: me.arrayProducto[0]['codigo'],
                            producto: me.arrayProducto[0]['nombre'],
                            cantidad: 1,
                            costo: me.arrayProducto[0]['precio_costo'],
                            precio: me.arrayProducto[0]['precio_venta'],
                            descuento: 0,
                            stock: me.arrayProducto[0]['stock'],
                        });

                         }

                    */


                        /*
                         me.arrayDetalle.push({
                            idproducto: data['id'],
                            producto: data['nombre'],
                            cantidad: 1,
                            precio: data['precio_venta'],
                            costo: data['precio_costo'],
                            descuento:0,
                            stock:data['stock']

                        });
                        */
                       /*
                        console.log(me.idproducto);
                        console.log(me.codigo);
                       console.log(me.nombre);
                       console.log(me.costo);
                       console.log(me.precio);
                       console.log(me.stock);
                       */
                       me.codigo="";
                    }
                    else{

                            me.arrayProducto = [];

                         me.codigo = "";
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'No Existe el Codigo de Producto o El stock esta Agotado!',
                            })


                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

            },


             buscarBcliente(){
 let me=this;

              if(me.bcliente==""){

                  me.bcliente;
                        me.arrayCliente=[];

                  swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ingrese un Numero de Nit!',
                            })
                      me.arrayCliente=[];
                        me.idcliente="";
                       me.nit="";
                        me.nombrep="";
                        me.direccion="";
                        me.telefono="";

              }else{

                  var url= '/cliente/bcliente?filtro=' + me.bcliente;



                axios.get(url).then(function (response) {
                /*
                  var respuesta= response.data;
                    me.arrayProducto = respuesta.productos;
                    */
                    var respuesta= response.data;
                    me.arrayBuscarCliente = respuesta.buscaCliente;
                   /*
                      var respuesta= response.data;
                      me.arrayCliente = respuesta.cliente.data;
                   */



                    if(me.arrayBuscarCliente.length>0){



                       me.idcliente=me.arrayBuscarCliente[0]["id"];
                       me.nit=me.arrayBuscarCliente[0]["nit"];
                        me.nombrep=me.arrayBuscarCliente[0]["nombre"];
                        me.direccion=me.arrayBuscarCliente[0]["direccion"];
                        me.telefono=me.arrayBuscarCliente[0]["telefono"];


                       /*
                        me.nit=me.arrayBuscarCliente[1];
                        me.nombre=me.arrayBuscarCliente[2];
                        me.direccion=me.arrayBuscarCliente[3];
                        me.telefono=me.arrayBuscarCliente[4];
                        */


                    }

                    else{

                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'No existe el Nit!',
                            })

                        me.bcliente="";
                        me.arrayBuscarCliente=[];

                        me.idcliente="";
                       me.nit="";
                        me.nombrep="";
                        me.direccion="";
                        me.telefono="";



                    }



                })
                .catch(function (error) {
                    console.log(error);
                });

              }





        },



             selectLugar(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            selectLugar2(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar2 = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },




          /**************************************************************************************** */

            cambiarPagina(page,buscar,criterio,idlugar2){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarVenta(page,buscar,criterio,idlugar2);
            },

             cambiarPagina2(page,buscarA,criterioA,idlugar){
                let me = this;
                //Actualiza la página actual
                me.pagination2.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarProducto(page,buscarA,criterioA,idlugar);
            },

            cambiarPagina3(page,buscarG,criterioG){
                let me = this;
                //Actualiza la página actual
                me.pagination3.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarGift(page,buscarG,criterioG);
            },

          /**************************************************************************************** */
            encuentra(id){
                var sw=0;
                for(var i=0;i<this.arrayDetalle.length;i++){
                    if(this.arrayDetalle[i].idproducto==id){
                        sw=true;
                    }
                }
                return sw;
            },

          /**************************************************************************************** */
            eliminarDetalle(index){
                let me = this;
                me.arrayDetalle.splice(index, 1);
            },


           /**************************************************************************************** */
            agregarDetalle(){
                let me=this;
                if(me.idproducto==0 || me.cantidad==0 || me.precio==0){
                }
                else{
                    if(me.encuentra(me.idproducto)){
                        /*
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                            */


                         for(var i=0;i<this.arrayDetalle.length;i++){
                    if(me.arrayDetalle[i].idproducto==id){
                        me.arrayDetalle[i].cantidad++
                    }
                }
                    }
                    else{
                       me.arrayDetalle.push({
                            idproducto: me.idproducto,
                            codigo: me.codigo,
                            producto: me.producto,
                            cantidad: me.cantidad,
                            precio: me.precio,
                            costo: me.costo,

                             precionormal: me.precio,

                            precio01: me.precio01,
                            precio0: me.precio0,
                            precio1: me.precio1,
                            precio2: me.precio2,
                            precio3: me.precio3,
                            precio4: me.precio4,
                            stock: me.stock
                        });
                        me.codigo="";
                        me.idproducto=0;
                        me.producto="";
                        me.cantidad=0;
                        me.precio=0;
                        me.costo=0;
                        me.precionormal=0;

                        me.precio01=0;
                        me.precio0=0;
                        me.precio1=0;
                        me.precio2=0;
                        me.precio3=0;
                        me.precio4=0;
                        me.stock=0
                    }

                }



            },

             /**************************************************************************************** */
            agregarDetalleModal(data =[]){

                let me=this;
                if(me.encuentra(data['id'])){
                    /*
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                            */

                                for(var i=0;i<me.arrayDetalle.length;i++){

                    if(me.arrayDetalle[i].idproducto==data['id']){
                        me.arrayDetalle[i].cantidad= me.arrayDetalle[i].cantidad + 1;
                    }
                }


                    }
                    else{
                       me.arrayDetalle.push({
                            idproducto: data['id'],
                             codigo: data['codigo'],
                            producto: data['nombre'],
                            cantidad: 1,
                            precio: data['precio_venta'],
                            costo: data['precio_costo'],
                            precionormal: data['precio_venta'],
                            descuento:0,
                            precio01: data['precio01'],
                            precio0: data['precio0'],
                            precio1: data['precio1'],
                            precio2: data['precio2'],
                            precio3: data['precio3'],
                            precio4: data['precio4'],
                            stock:data['stock']

                        });
                    }
            },

            agregarDetalleModal2(data =[]){

                let me=this;
                if(me.encuentra(data['id'])){
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                    }
                    else{
                       me.arrayDetalle.push({
                            idproducto: data['id'],
                            codigo: data['codigo'],
                            producto: data['nombre'],
                            cantidad: 1,
                            precio: data['precio_venta'],
                            costo: data['precio_costo'],
                            descuento:0,
                            precionormal: data['precio_venta'],
                            precio01: data['precio01'],
                            precio0: data['precio0'],
                            precio1: data['precio1'],
                            precio2: data['precio2'],
                            precio3: data['precio3'],
                            precio4: data['precio4'],
                            stock:data['stock']

                        });
                    }
            },

            /**************************************************************************************** */
            agregarClienteModal(data =[]){


                let me=this;


                                me.idcliente=data['id'];
                                me.nit = data['nit'];
                                me.nombrep = data['nombre'];
                                me.direccion = data['direccion'];
                                me.telefono = data['telefono'];
                this.cerrarModalB();
                this.bcliente = "";

            },

            registrarCliente(){
                if (this.validarCliente()){
                    return;
                }

                let me = this;
                 me.deshabilitar_boton=1;
                axios.post('/cliente/registrar',{


                    'nit': this.nit,
                    'nombre': this.nombre,
                    'direccion': this.direccion,
                    'telefono' : this.telefono

                }).then(function (response) {

                    me.bcliente ="";
                    me.bcliente = me.nit;

                    me.cerrarModalC();
                    me.deshabilitar_boton=0;

                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                }).catch(function (error) {
                    console.log(error);
                });
            },
             /**************************************************************************************** */
            listarProducto (page,buscarA,criterioA,idlugar){
                let me=this;
                var url= '/existencia/listarProducto?page=' + page + '&buscar='+ buscarA + '&criterio='+ criterioA + '&idlugar='+ idlugar[0];
                axios.get(url).then(function (response) {
                    //console.log(response);
                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos.data;
                    me.pagination2= respuesta.pagination2;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },


            pdfVenta(id){

                // window.open(this.urlhttp+'/venta/pdf/'+id+','+'_blank');
                 window.open('/venta/pdf/'+id+','+'_blank');


            },




           cotizacion(){



                  if (this.validarCotizacion()){
                    return;
                }


document.getElementById("costo").style.display === "none";
document.getElementById("totalc").style.display === "none";
document.getElementById("totalu").style.display === "none";
document.getElementById("totalc2").style.display === "none";
document.getElementById("totalu2").style.display === "none";


                Swal.fire({
                    title: 'Cotizacion a Nombre de:',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    showLoaderOnConfirm: true,
                    preConfirm: (login) => {

                        var f = new Date();

                        var fecha = (f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear());

                         this.cotizar==1;



               const doc = new jsPDF();

                        doc.setFontSize(7);
                        doc.text(this.idlugar[1],  20, 8);
                         doc.setFontSize(7);
                        doc.text("Direccion: "+this.idlugar[2]+" TEL:", 20, 12);



                        doc.setFontSize(10);
                        doc.text("Cotizacion a Nombre De: "+login,100,5);
                        doc.setFontSize(7);
                        doc.text("Fecha: "+fecha,185,5);
                        doc.addImage("img/logo.jpg", "PNG", 5, 2, 10, 10);
                        doc.setFontSize(7);
                        doc.text("VIGENCIA DE COTIZACION Tiempo DESPUES DE SU EMISION",100,10);

                        doc.autoTable({html: '#producto'});


                doc.save("cotizacion.pdf");

                        this.cotizar==0;



                    },
                    allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                        title: `${result.value.login}'s avatar`,
                        imageUrl: result.value.avatar_url
                        })
                    }
                    })

    document.getElementById("costo").style.display === "block";
    document.getElementById("totalc").style.display === "block";
    document.getElementById("totalu").style.display === "block";
    document.getElementById("totalc2").style.display === "block";
    document.getElementById("totalu2").style.display === "block";


           },

 /**************************************************************************************** */
                 ventaPos(){





/*


                  let cambio= this.total - this.recibido;


               // validarUser();


                    if (this.validarVentap()){
                    return;
                }
               /*
               var totalporcentaje = (parseFloat(this.total)*0.05);
               var grantotal = (parseFloat(totalporcentaje)+parseFloat(this.total));
            */
/*
                Swal.fire({
                    title: 'Numero de Comprobante:',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    showLoaderOnConfirm: true,
                    preConfirm: (numcop) => {


                          if (this.validarVentap()){
                                   return;
                             }

                             if(!numcop){

                             }else{



                   let me = this;
                me.deshabilitar_boton=1;

                axios.post('/ventap/registrar',{

                    'idcliente': this.idcliente,
                    'tipo_comprobante': 'POS',
                    //'serie_comprobante' : this.serie_comprobante,
                    'num_comprobante' : numcop,
                    //'impuesto' : this.impuesto,
                    'idlugar' : this.idlugar[0],

                    'totalc' : this.totalc,
                    'totalu' : this.totalu,
                    'total' : this.total,

                    'data': this.arrayDetalle

                }).then(function (response) {
                    console.log(response);
                    me.listado=1;
                    me.listarVenta(1,'','fecha_hora',me.idlugar2);

                    me.idcliente=0;

                    me.tipo_comprobante='';
                    //me.serie_comprobante='';
                    //me.num_comprobante='';
                    //me.impuesto=0.18;
                    me.total=0.0;
                    me.totalc=0.0;
                    me.totalu=0.0;
                    me.idproducto=0;
                    me.producto='';
                    me.cantidad=0;
                    me.costo=0;
                    me.precio=0;
                    me.descuento=0;
                    me.recibido=0;
                    me.arrayDetalle=[];
                    me.arrayCliente=[];



                     me.cerrarModal();
                     me.deshabilitar_boton=0;

                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500

                        });

                    setTimeout(function(){
                        window.open(me.urlhttp+'/ventap/pdf/'+response.data.id+','+'_blank');
                     }, 1000);


                }).catch(function (error) {
                    console.log(error);
                });

                             }


                    },
                    allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                        title: `${result.value.login}'s avatar`,
                        imageUrl: result.value.avatar_url
                        })
                    }
                    })

*/
           },



                 /**************************************************************************************** */
            listarCliente (buscarB,criterioB){
                 let me=this;
                var url= '/cliente/listarCliente?buscarB='+ buscarB + '&criterioB='+ criterioB;

                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayCliente = respuesta.cliente.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
             /**************************************************************************************** */




               registrarVenta: async function(){


                if (this.validarVenta()){
                    return;
                }

                let numeroMembresia=0;
                let numM=0;
                let IDPunto=0;
                let saldoActual=0;
                this.mensaje="";

                let cambio= this.restaTotalT - this.recibido;
                let numerocomprobante ="";
                let seriecomrobante = "";
                let cargaPuntos="";
                let caja="";
                let saldoP=0;
                let totalR = this.restaTotalT;

                let totalGifts = this.totalGift;
                let totalMePuntos = this.totalPuntos;




                let me = this;

                me.totalc = me.calcularTotalc;
                me.totalu = me.calcularTotalu;

                                me.deshabilitar_boton=1;


                // console.log(this.arrayDetalle);

                                if(this.serie_comprobante !==''){

                                        seriecomrobante = this.serie_comprobante;
                                        //console.log(seriecomprobante);

                                    }else{
                                        seriecomrobante = 0;
                                        //console.log(seriecomprobante);
                                    }

                                if(this.num_comprobante !== ''){

                                    numerocomprobante = this.num_comprobante;
                                // console.log(numerocomprobante);

                                }else{

                                    numerocomprobante = 0;
                                    //console.log(numerocomprobante);
                                }





                if(this.arrayDetalleP.length>0){

                   numM = this.arrayDetalleP[0]['codigoP'];
                   //console.log(" numM"+numM);
                   IDPunto = this.arrayDetalleP[0]['idPunto'];

                   saldoActual = this.arrayDetalleP[0]['saldo'];

                }

               // Si existe Contado Valor Caja
                if(totalR>0){

                        cargaPuntos = parseFloat(totalR/100).toFixed(2);
                        caja=1;

                        }

                        if(totalR==0){

                        cargaPuntos = 0;
                        caja=0;
                        // console.log(cargaPuntos);
                        // console.log(caja);
                        }

                //console.log(numM);

/*
                if(this.arrayDetalleP.length > 0){

                    saldoP = (this.arrayDetalleP[0]['saldo']-this.arrayDetalleP[0]['punto']);
                    //console.log(saldoP);
                    }
*/

             // Si la Membresia Existe
                if(numM>0){

                 //   console.log("en el if");
                 //   console.log(numM);



                              await axios.post('/venta/registrar',{
                                    'idcliente': this.idcliente,
                                    'tipo_comprobante': this.tipo_comprobante,
                                    'recibido': this.recibido,
                                    'cambio': this.cambio,
                                    'idlugar' : this.idlugar[0],
                                    'nombrelugar': this.nombrelugar,
                                    'direccionlugar':this.direccionlugar,

                                    'serie_comprobante' : seriecomrobante,
                                    'num_comprobante' : numerocomprobante,
                                    //'impuesto' : this.impuesto,
                                    'totalc' : me.totalc,
                                    'totalu' : me.totalu,
                                    'total' : totalR,
                                    'IDPunto' : IDPunto,
                                    'totalGi' :totalGifts,
                                    'totalPun' :totalMePuntos,

                                    'caja': caja,
                                    'saldop': saldoP,
                                    'saldoActual': saldoActual,
                                    'cargarpuntos' : cargaPuntos,
                                    'numM' : numM,
                                    'data': this.arrayDetalle,
                                    'dataG': this.arrayDetalleG,
                                    'dataP': this.arrayDetalleP

                                }).then(function (response) {
                               // console.log(response);
                                    me.listado=1;
                                    me.listarVenta(1,'','fecha_hora',me.idlugar2);

                                    me.idcliente=0;

                                    me.tipo_comprobante='';
                                    //me.serie_comprobante='';
                                    //me.num_comprobante='';
                                    //me.impuesto=0.18;
                                    me.total=0.0;
                                    me.totalc=0.0;
                                    me.totalu=0.0;
                                    me.idproducto=0;
                                    me.producto='';
                                    me.cantidad=0;
                                    me.costo=0;
                                    me.precio=0;
                                    me.descuento=0;
                                    me.recibido=0;
                                    me.arrayDetalle=[];
                                    me.arrayCliente=[];
                                    me.arrayDetalleG=[];
                                    me.arrayDetalleP=[];


                                    me.restaTotalT=0;
                                    me.totalGift=0;
                                    me.totalPuntos=0;

                                    me.deshabilitar_boton=0;

                                    me.cerrarModal();
                                    me.cerrarModalC();




                                    Swal.fire({
                                        position: 'top',
                                        icon: 'success',
                                        title: 'Datos Agregados Con Exito!',
                                        showConfirmButton: false,
                                        timer: 1000

                                        });

                                        setTimeout(function(){



                                           // window.open(me.urlhttp+'/venta/pdf/'+response.data.id+','+'_blank');
                                            window.open('/venta/pdf/'+response.data.id+','+'_blank');

                                        }, 500);




                                }).catch(function (error) {
                                    console.log(error);
                                });



            // Si la Membresia no Existe
                }else {

              //  console.log("entor a else");
               await Swal.fire({
                    title: 'Ingrese Membresia:',
/*
                    html: `
                    <div class="input-group">
                        <select id="criterioT" class="form-control col-md-3" >
                        <option value="codigo">Codigo</option>
                        <option value="telefono">Telefono</option>
                        </select>

                    </div>

                        `,
*/
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    showLoaderOnConfirm: true,
                    preConfirm: (numM) => {

                        //const criterioT = document.getElementById("criterioT").value;
                        //console.log(numM);
                        // Si no ingreso la membresia


                        if(!numM | numM==0){

                            numM=0;



                                    axios.post('/venta/registrar',{
                                        'idcliente': this.idcliente,
                                        'tipo_comprobante': this.tipo_comprobante,
                                        'recibido': this.recibido,
                                        'cambio': this.cambio,
                                        'idlugar' : this.idlugar[0],
                                        'nombrelugar': this.nombrelugar,
                                        'direccionlugar':this.direccionlugar,

                                        'serie_comprobante' : seriecomrobante,
                                        'num_comprobante' : numerocomprobante,
                                        //'impuesto' : this.impuesto,
                                        'totalc' : me.totalc,
                                        'totalu' : me.totalu,
                                        'total' : totalR,
                                        'IDPunto' : IDPunto,
                                        'totalGi' :totalGifts,
                                       'totalPun' :totalMePuntos,

                                        'caja': caja,
                                        'saldop': saldoP,
                                        'saldoActual': saldoActual,
                                        'cargarpuntos' : cargaPuntos,
                                        'numM' : numM,
                                        'data': this.arrayDetalle,
                                        'dataG': this.arrayDetalleG,
                                        'dataP': this.arrayDetalleP

                                    }).then(function (response) {
                                   // console.log(response);
                                        me.listado=1;
                                        me.listarVenta(1,'','fecha_hora',me.idlugar2);

                                        me.idcliente=0;

                                        me.tipo_comprobante='';
                                        //me.serie_comprobante='';
                                        //me.num_comprobante='';
                                        //me.impuesto=0.18;
                                        me.total=0.0;
                                        me.totalc=0.0;
                                        me.totalu=0.0;
                                        me.idproducto=0;
                                        me.producto='';
                                        me.cantidad=0;
                                        me.costo=0;
                                        me.precio=0;
                                        me.descuento=0;
                                        me.recibido=0;
                                        me.arrayDetalle=[];
                                        me.arrayCliente=[];
                                        me.arrayDetalleG=[];
                                        me.arrayDetalleP=[];


                                        me.restaTotalT=0;
                                        me.totalGift=0;
                                        me.totalPuntos=0;

                                        me.deshabilitar_boton=0;

                                        me.cerrarModal();
                                        me.cerrarModalC();




                                        Swal.fire({
                                            position: 'top',
                                            icon: 'success',
                                            title: 'Datos Agregados Con Exito!',
                                            showConfirmButton: false,
                                            timer: 1000

                                            });

                                            setTimeout(function(){



                                               // window.open(me.urlhttp+'/venta/pdf/'+response.data.id+','+'_blank');
                                               window.open('/venta/pdf/'+response.data.id+','+'_blank');

                                            }, 500);




                                    }).catch(function (error) {
                                        console.log(error);
                                    });



                        }else{




                                       axios.put('/puntos/validar',{

                                        'codigo': numM,

                                        }).then(response => {

                                            this.mensaje = response.data;
                                        //console.log(this.mensaje);

                                          if(this.mensaje==="No"){

                                                me.deshabilitar_boton=0;
                                            return ( Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'Hay un error en el numero de Membresia!',


                                          })
                                          );



                                           }else{


                                            //console.log("Sigue de largo");




                                            //console.log(this.arrayDetalleG);
                                            //console.log("-------")
                                            //console.log(this.arrayDetalleP);


                                           axios.post('/venta/registrar',{
                                                'idcliente': this.idcliente,
                                                'tipo_comprobante': this.tipo_comprobante,
                                                'recibido': this.recibido,
                                                'cambio': this.cambio,
                                                'idlugar' : this.idlugar[0],
                                                'nombrelugar': this.nombrelugar,
                                                'direccionlugar':this.direccionlugar,

                                                'serie_comprobante' : seriecomrobante,
                                                'num_comprobante' : numerocomprobante,
                                                //'impuesto' : this.impuesto,
                                                'totalc' : me.totalc,
                                                'totalu' : me.totalu,
                                                'total' : totalR,
                                                'IDPunto' : IDPunto,
                                                'totalGi' :totalGifts,
                                                'totalPun' :totalMePuntos,

                                                'caja': caja,
                                                'saldop': saldoP,
                                                'saldoActual': saldoActual,
                                                'cargarpuntos' : cargaPuntos,
                                                'numM' : numM,
                                                'data': this.arrayDetalle,
                                                'dataG': this.arrayDetalleG,
                                                'dataP': this.arrayDetalleP

                                            }).then(function (response) {
                                         //   console.log(response);
                                                me.listado=1;
                                                me.listarVenta(1,'','fecha_hora',me.idlugar2);

                                                me.idcliente=0;

                                                me.tipo_comprobante='';
                                                //me.serie_comprobante='';
                                                //me.num_comprobante='';
                                                //me.impuesto=0.18;
                                                me.total=0.0;
                                                me.totalc=0.0;
                                                me.totalu=0.0;
                                                me.idproducto=0;
                                                me.producto='';
                                                me.cantidad=0;
                                                me.costo=0;
                                                me.precio=0;
                                                me.descuento=0;
                                                me.recibido=0;
                                                me.arrayDetalle=[];
                                                me.arrayCliente=[];
                                                me.arrayDetalleG=[];
                                                me.arrayDetalleP=[];


                                                me.restaTotalT=0;
                                                me.totalGift=0;
                                                me.totalPuntos=0;

                                                me.deshabilitar_boton=0;

                                                me.cerrarModal();
                                                me.cerrarModalC();




                                                Swal.fire({
                                                    position: 'top',
                                                    icon: 'success',
                                                    title: 'Datos Agregados Con Exito!',
                                                    showConfirmButton: false,
                                                    timer: 1000

                                                    });

                                                    setTimeout(function(){



                                                        //window.open(me.urlhttp+'/venta/pdf/'+response.data.id+','+'_blank');
                                                        window.open('/venta/pdf/'+response.data.id+','+'_blank');

                                                    }, 500);




                                            }).catch(function (error) {
                                                console.log(error);
                                            });


                                        }




                                        })
                                        .catch(error => {
                                        console.log(err);
                                        });

                        }






                    },
                    allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                        title: `${result.value.login}'s avatar`,
                        imageUrl: result.value.avatar_url
                        })
                    }
                    })
                    this.deshabilitar_boton=0;
                }



            },

             /**************************************************************************************** */

            validarUser(){

               // window.location.href = '/logout';

/*
                axios.post('/user/activado',{


                }).then(function (response) {

                    console.log(response);
                  if(response){

                  }
                  else{

                        window.location.href = 'https://127.0.0.1:8000/logout';



                  }




                }).catch(function (error) {
                    console.log(error);
                });
*/
            },


            validarVenta(){


                this.errorVenta=0;
                this.errorMostrarMsjVenta =[];

                if (this.idcliente==0) this.errorMostrarMsjVenta.push("Seleccione un Cliente");
                if (this.idlugar[0]=="") this.errorMostrarMsjVenta.push("Seleccione una Tienda");
                if (this.tipo_comprobante==0) this.errorMostrarMsjVenta.push("Seleccione el comprobante");

               // if (!this.num_comprobante) this.errorMostrarMsjVenta.push("Ingrese el número de comprobante");
                //if (!this.impuesto) this.errorMostrarMsjVenta.push("Ingrese el impuesto de venta");
                if (this.arrayDetalle.length<=0) this.errorMostrarMsjVenta.push("Ingrese detalles");




                if(this.arrayDetalleG.length === 0 && this.arrayDetalleP.length === 0){

                    if(this.recibido==0) {
                       this.errorMostrarMsjVenta.push("El Monto recibido no puede ser 0");
                    }

                    if(this.cambio<0) {
                    this.errorMostrarMsjVenta.push("El Cambio no puede ser Negativo");
                     }
                }else{


                    if(this.arrayDetalleP.length === 0){

                        if(this.cambio < 0 ){
                        this.errorMostrarMsjVenta.push("Por favor Revice el Monto Ingresado");
                        }


                    }else{

                        if(this.cambio < 0 ){
                        this.errorMostrarMsjVenta.push("Por favor Revice el Monto Ingresado");
                        }


                        if(this.arrayDetalleP[0]['saldo'] < this.arrayDetalleP[0]['punto'] ){
                        this.errorMostrarMsjVenta.push("No puede Ingresar mas Puntos que los que permite el saldo");
                    }



                    }




                }





                if (this.errorMostrarMsjVenta.length) this.errorVenta = 1;

                return this.errorVenta;
            },

            validarVentap(){
                this.errorVentap=0;
                 this.errorMostrarMsjVenta =[];

                if (this.idcliente==0) this.errorMostrarMsjVenta.push("Seleccione un Cliente");
                if (this.idlugar[0]=="") this.errorMostrarMsjVenta.push("Seleccione una Tienda");
                if (this.tipo_comprobante==0) this.errorMostrarMsjVenta.push("Seleccione el comprobante");

                //if (!this.impuesto) this.errorMostrarMsjVentap.push("Ingrese el impuesto de ventap");
                if (this.arrayDetalle.length<=0) this.errorMostrarMsjVenta.push("Ingrese detalles");
/*
                if(this.recibido==0) {
                   this.errorMostrarMsjVentap.push("El Monto recibido no puede ser 0");
                }
                if(this.cambio<0) {
                    this.errorMostrarMsjVentap.push("El Cambio no puede ser Negativo");
                }
*/
                if (this.errorMostrarMsjVenta.length) this.errorVenta = 1;

                return this.errorVenta;
            },


            validarCotizacion(){
                this.errorVenta=0;
                this.errorMostrarMsjVenta =[];


               // if (!this.num_comprobante) this.errorMostrarMsjVenta.push("Ingrese el número de comprobante");
                //if (!this.impuesto) this.errorMostrarMsjVenta.push("Ingrese el impuesto de venta");
                if (this.arrayDetalle.length<=0) this.errorMostrarMsjVenta.push("Ingrese detalles");



                if (this.errorMostrarMsjVenta.length) this.errorVenta = 1;

                return this.errorVenta;
            },

            validarCliente(){


               this.errorCliente=0;
                this.errorMostrarMsjCliente  =[];


                if (!this.nit){
                  this.errorMostrarMsjCliente .push("El nit no puede estar vacío.");

                }
                if (!this.nombre){
                   this.errorMostrarMsjCliente.push("El nombre no puede estar vacio.");

                }
                /*
                if (!this.direccion){
                  this.errorMostrarMsjCliente.push("La Direccion no puede estar vacío.");

                }

                if (!this.telefono){
                  this.errorMostrarMsjCliente.push("El telefono no puede estar vacío.");

                }
                */


                if (this.errorMostrarMsjCliente.length) this.errorCliente = 1;

                return this.errorCliente;

            },


             /**************************************************************************************** */
            mostrarDetalle(){
                let me=this;
                me.listado=0;

                me.idcliente=0;
                me.nit='';
                me.nombre='';
                me.direccion='';
                me.telefono='';
                me.tipo_comprobante='';
                me.serie_comprobante='';
                me.num_comprobante='';
                //me.impuesto=0.18;
                me.total=0.0;
                me.totalc=0.0;
                me.totalu=0.0;
                me.idproducto=0;
                me.producto='';
                me.cantidad=0;
                me.precio=0;
                me.arrayDetalle=[];
            },

             /**************************************************************************************** */
            ocultarDetalle(){
                this.listado=1;
                this.cerrarModal();
            },

             /**************************************************************************************** */
            verVenta(id){
                let me=this;
                me.listado=2;

                //Obtener los datos del venta
                var arrayVentaT=[];
                var url= '/venta/obtenerCabecera?id=' + id;

                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    arrayVentaT = respuesta.venta;

                    me.cliente = arrayVentaT[0]['nombre'];
                    me.tipo_comprobante=arrayVentaT[0]['tipo_comprobante'];
                    me.serie_comprobante=arrayVentaT[0]['serie_comprobante'];
                    me.num_comprobante=arrayVentaT[0]['num_comprobante'];
                    me.estado=arrayVentaT[0]['estado'];
                    //me.impuesto=arrayVentaT[0]['impuesto'];

                    me.total=arrayVentaT[0]['total'];
                    me.totalc=arrayVentaT[0]['totalc'];
                    me.totalu=arrayVentaT[0]['totalu'];

                })
                .catch(function (error) {
                    console.log(error);
                });

                //Obtener los datos de los detalles
                var urld= '/venta/obtenerDetalles?id=' + id;

                axios.get(urld).then(function (response) {
                    //console.log(response);
                    var respuesta= response.data;
                    me.arrayDetalle = respuesta.detalles;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

             /**************************************************************************************** */
            cerrarModal(){
                this.modal=0;
                this.tituloModal='';
                this.modal2=0;
                this.recibido='';
                this.cambio='';
                this.desbloquear=0;
                this.deshabilitar_boton=0;






                this.arrayBuscarCliente=[];
                this.bcliente="";

                this.arrayGift=[];
                this.arrayDetalleG=[];
                this.arrayDetalleP=[];
                this.arrayPunto=[];
                this.caja=0;
                this.restaTotalT=0;
                this.totalGift=0;
                this.totalPuntos=0;




            },

             /**************************************************************************************** */
            abrirModal(){
                this.arrayProducto=[];
                this.modal = 1;
                this.tituloModal = 'Seleccione uno o varios productos';
            },




             abrirModalAlarma(modelo2, accion, data = []){
              switch(modelo2){
                    case "producto":
                    {
                      switch(accion){
                        case 'alarma':
                            {
                              this.modal2=1;
                              this.tituloModal2='Stock Minimo';
                              break;
                            }
                      }
                    }
              }

            },

             /**************************************************************************************** */
             /*
            desactivarVenta(id){
               swal.fire({
                title: 'Esta seguro de anular este venta?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
                }).then((result) => {
                if (result.value) {
                    let me = this;

                    axios.put('/venta/desactivar',{
                        'id': id
                    }).then(function (response) {
                        me.listarVenta(1,'','tipo_comprobante');
                        swal.fire(
                        'Anulado!',
                        'El venta ha sido anulado con éxito.',
                        'success'
                        )
                    }).catch(function (error) {
                        console.log(error);
                    });


                } else if (
                    // Read more about handling dismissals
                    result.dismiss === swal.DismissReason.cancel
                ) {

                }
                })
            },

*/

            obtenerPuntos(id){

                let me = this;
                let idventa = id;





/*

               console.log("Puntos");
               await axios.put('/puntos/obtener',{

                    'idventa': idventa

                    }).then(response => {
                        console.log(response);
                    var idp = response.data;
                    me.puntoAnulacion = idg.arrayPunto.data;
                    // console.log(giftarray);


                    if(me.puntoAnulacion.length === 0){
                    console.log("Entro al if P");
                    }


                    })
                    .catch(error => {
                    console.log(err);
                    });
*/

            },








             desactivarVenta(id,num_comprobante,total,totalg,totalm){

                let me = this;
                let idventa = id;
                let lugar2 = this.idlugar2;






                //this.obtenerPuntos(id);
                //this.obtenerGift(id);


                 //Obtener carga
                 var url= '/puntoscarga/obtener?idventa=' + id;
               axios.get(url).then(function (response) {
                   // console.log(response);
                    var rescarga= response.data;
                    me.arrayAnularCargar = rescarga.cargar;


                })
                .catch(function (error) {
                    console.log(error);
                });


                    //Obtener Puntos
                    var url= '/puntos/obtener?idventa=' + id;
               axios.get(url).then(function (response) {
                    //console.log(response);
                    var respunto= response.data;
                    me.arrayAnularPunto = respunto.puntos;

                })
                .catch(function (error) {
                    console.log(error);
                });


                   //Obtener Gift
                var urlgif= '/gift/obtener?idventa=' + id;
             axios.get(urlgif).then(function(response) {
                   // console.log(response);
                    var resgift= response.data;
                    me.arrayAnularGift = resgift.gifts;

                })
                .catch(function (error) {
                    console.log(error);
                });









                 var urld= '/venta/obtenerDetalles?id=' + id +'&num_comprobante='+num_comprobante;

                axios.get(urld).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayAnularV = respuesta.detalles;





                })
                .catch(function (error) {
                    console.log(error);
                });




                this.detallelist(id,lugar2,num_comprobante,total,totalg,totalm);



                },


         /**************************************************************************************** */
           detallelist: async function(id,lugar2,num_comprobante,total,totalg,totalm){
/*
               Swal.fire({
                title: 'Esta seguro/a que desea Anular esta Venta?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {


                if (result.value) {
                  let me = this;

                  console.log(total);
                      console.log(num_comprobante);
                       console.log(lugar2);
                        console.log(id);
                        console.log(this.arrayAnularV);

                axios.put('/venta/desactivar',{
                          'id': id,
                          'num_comprobante':num_comprobante,
                          'total':total,
                          'lugar2': lugar2,
                          'data': this.arrayAnularV


                        }).then(function(response){
                          //  console.log(response);
                          me.listarVenta(1,'','fecha_hora',lugar2);
                          me.arrayAnularV=[];
                          Swal.fire(
                            'Anulado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });
                 // console.log(this.arrayAnularV);
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })

*/
let me = this;

              await Swal.fire({
                    title: 'Razon de Anulacion:',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    showLoaderOnConfirm: true,
                    preConfirm: (anular) => {

/*
                          if (this.validarVentap()){
                                   return;
                             }
*/
                             if(!anular){
                               // console.log("dentrodeif");

                             }else{





                  /*
                  console.log(total);
                      console.log(num_comprobante);
                       console.log(lugar2);
                        console.log(id);
                        console.log(this.arrayAnularV);
                     */
                    //console.log(this.arrayAnularGift);

                      axios.put('/venta/desactivar',{
                          'id': id,
                          'num_comprobante':num_comprobante,
                          'total':total,
                           'totalg':totalg,
                           'totalm':totalm,
                          'anular': anular,
                          'lugar2': lugar2,
                          'data': this.arrayAnularV,
                          'gift': this.arrayAnularGift,
                          'punto': this.arrayAnularPunto,
                          'cargar': this.arrayAnularCargar


                        }).then(function(response){
                           // console.log(response);
                          me.listarVenta(1,'','fecha_hora',lugar2);
                          me.arrayAnularV=[];
                          me.arrayAnularGift=[];
                          me.arrayAnularPunto=[];
                          me.arrayAnularCargar=[];


                              Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Anulacion Exitosa!',
                          showConfirmButton: false,
                          timer: 1500

                        });

                        }).catch(function(error){
                          console.log(error);

                        });



                             }

                    },

                    allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                        title: `${result.value.login}'s avatar`,
                        imageUrl: result.value.avatar_url
                        })
                    }
                    })


           },


           VerAnulacion: async function(id,num_comprobante,total)
           {

              let me=this;
                var url= '/venta/veranulacion';
               await axios.post(url).then(function (response) {
                   // console.log(response);

                    var respuesta= response.data;
                    me.arrayAnulacion = respuesta.anular;

                     if(me.arrayAnulacion.length>0){



                       me.razonAnulacion=me.arrayAnulacion[0]["descripcion"];




                     Swal.fire({
                     icon: 'Anulada',
                     title: 'Ver Razon',
                     text: 'Razon: '+me.razonAnulacion,

                    })

                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

           },


                 cerrarModalB(){
                this.modalB=0;
                this.tituloModalB='';
            },
            abrirModalB(){
                this.arrayCliente=[];
                this.modalB = 1;
                this.tituloModalB = 'Seleccione el cliente';
            },

             cerrarModalC(){
                this.modalC=0;
                this.tituloModalC='';

                this.nit='';
                this.nombrep='';
                this.direccion='';
                this.telefono='';
                this.errorCliente=0;
                this.val1=0;
                this.val2=0;
                this.val3=0;
                this.val4=0;
                this.deshabilitar_boton=0;
            },
            abrirModalC(){

                this.nit='';
                this.nombre='';
                this.direccion='';
                this.telefono='';

                this.modalC = 1;
                this.tituloModalC = 'Registrar Cliente';

            },

             duplicado(){

               axios.put('/cliente/validar',{

                    'nit': this.nit,
                    'nombre': this.nombre,


                }).then(response => {
                  this.mensaje = response.data;
                  if(this.mensaje==="Existe"){
                      this.deshabilitar_boton=1;

                  }else{
                    this.deshabilitar_boton=0;
                  }
                })
                .catch(error => {
                   console.log(err);
                 });

            },

             mostrarDetalle(){
                let me=this;
                me.listado=0;

                me.idcliente=0;
                me.nombre='';
                me.direccion='';
                me.telefono='';
                me.tipo_comprobante='';
                me.serie_comprobante='';
                me.num_comprobante='';
                //me.impuesto=0.18;
                me.total=0.0;
                me.totalc=0.0;
                me.totalu=0.0;
                me.idproducto=0;
                me.producto='';
                me.cantidad=0;
                me.precio=0;
                me.costo=0;
                me.arrayDetalle=[];
            },
            ocultarDetalle(){
                this.listado=1;
                this.cerrarModal();
            },



           /** Metodos_gift */


           listarGift(page,buscarG,criterioG){

            let me=this;
                 var url= '/giftcard/buscarGift?page=' + page + '&buscar='+ buscarG + '&criterio='+ criterioG;
                axios.get(url).then(function (response) {
                 // console.log(response);
                    var respuesta= response.data;
                    me.arrayGift = respuesta.gifts.data;
                    me.pagination3= respuesta.pagination3;
                })
                .catch(function (error) {
                    console.log(error);
                });




            },



           abrirGift(){


        this.codigoG='';
        this.monto='';
        this.arrayGift=[];
        this.modalG = 1;
        this.tituloModalG ='GiftCard';

},



agregarDetalleModalG(data =[]){

    let me=this;
    if(me.encuentraG(data['id'])){
            swal.fire({
                type: 'error',
                title: 'Error...',
                text: 'Esa GitfCard ya se encuentra agregado!',
                })
        }
        else{
           me.arrayDetalleG.push({
                idgift: data['id'],
                codigoG: data['codigo'],
                montoG: data['monto'],
                estadoG: data['estado']


            });
        }
},

encuentraG(id){
                var sw=0;
                for(var i=0;i<this.arrayDetalleG.length;i++){
                    if(this.arrayDetalleG[i].idgift==id){
                        sw=true;
                    }
                }
                return sw;
            },

          /**************************************************************************************** */
            eliminarDetalleG(index){
                let me = this;
                me.arrayDetalleG.splice(index, 1);
            },



 cerrarModalG(){
    this.modalG=0;
    this.tituloModalG='';

    this.codigoG='';
    this.montoG='';


},


 /** Metodos_Puntos */

 listarPunto(page,buscarP,criterioP){

let me=this;
     var url= '/puntos/buscarPunto?page=' + page + '&buscar='+ buscarP + '&criterio='+ criterioP;
    axios.get(url).then(function (response) {
      //console.log(response);
        var respuesta= response.data;
        me.arrayPunto = respuesta.puntos.data;
        me.pagination3= respuesta.pagination3;
    })
    .catch(function (error) {
        console.log(error);
    });




},



    abrirPunto(){


    this.codigoP='';
    this.monto='';
    this.arrayPunto=[];
    this.modalP = 1;
    this.tituloModalG ='Membresia';

    },



    agregarDetalleModalP(data =[]){

    let me=this;
    if(me.encuentraP(data['id'])){
    swal.fire({
        type: 'error',
        title: 'Error...',
        text: 'Esa Membresia ya se encuentra agregada!',
        })
    }
    else{
    me.arrayDetalleP.push({
        idPunto: data['id'],
        codigoP: data['codigo'],
        saldo: data['saldo'],
        punto: 1,
        estadoP: data['estado']


    });
    }
    },

encuentraP(id){
    var sw=0;
    for(var i=0;i<this.arrayDetalleP.length;i++){
        if(this.arrayDetalleP[i].idPunto==id){
            sw=true;
        }
    }
    return sw;
},

/**************************************************************************************** */
eliminarDetalleP(index){
    let me = this;
    me.arrayDetalleP.splice(index, 1);
},



    cerrarModalP(){
    this.modalP=0;
    this.tituloModalP='';

    this.codigoP='';
    this.saldo='';
    this.punto='';


    },






        },






        mounted() {
            this.selectLugar2();
            this.listarVenta(1,this.buscar,this.criterio,this.idlugar2);
            this.selectLugar();

        }
    }
</script>
<style>
    .modal-content{
        width: 100% !important;
        position: absolute !important;
    }
    .mostrar{
        display: list-item !important;
        opacity: 1 !important;
        position: absolute !important;
        background-color: #3c29297a !important;
    }
    .div-error{
        display: flex;
        justify-content: center;
    }
    .text-error{
        color: red !important;
        font-weight: bold;
    }
    @media (min-width: 600px) {
        .btnagregar {
            margin-top: 2rem;
        }
    }

</style>
